
import ContactForm from '../Components/ContactForm';
import Information from '../Components/Information';
function Contact () {
    return ( 
    <div className='contact'>
      
        <Information/>
        <ContactForm/>

      

    
    </div> );
}

export default Contact ;