import Cut from "../Components/Cut";
import Header from "../Components/Header";
import Recorde from "../Components/Recode";
import SecServices from "../Components/home/SectionService";

export default function Services(params) {
    return<>
    <Header/>
    <SecServices />
   
    <Recorde/>
    <Cut/>

    </>
}